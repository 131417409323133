
import {
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  shallowRef,
  h,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiBase, ApiMerchant } from "@/core/api";
import AddKeyParametersModal from "@/views/merchants/AddKeyParametersModal.vue";
import AddParentRelationshipModal from "@/views/merchants/AddParentRelationshipModal.vue";
import AddRelatedRelationshipModal from "@/views/merchants/AddRelatedRelationshipModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import {
  commonChangeDefaultDate,
  setModuleBCN,
} from "@/core/directive/function/common";

interface TaggingItem {
  label: string;
  value: number;
}

export default defineComponent({
  name: "merchants-information",
  components: {
    AddKeyParametersModal,
    AddParentRelationshipModal,
    AddRelatedRelationshipModal,
  },
  setup() {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();
    const { t } = useI18n();

    const formData = ref({
      id: route.params.id,
      status: 0,
      name: "",
      short_key: "",
      last_validation_date: "",
      lifecycle_status: "",
      email: "",
      website: "",
      telephone: "",
      telephone2: "",
      fax: "",
      address: "",
      zip_code: "",
      city: "",
      country_iso_2: "",
      managing_directors: "",
      ut_commercial_registry: "",
      lei_register: "",
      vat_registration_number: "",
      year_of_incorporation: "",
      industry: [],
      corporation_type: "",
      corporation_group_id: "",
      position_in_group: "",
      channels_mgmt: "",
      overall_notes: "",
      inter_contact: "",
      __show: {
        created_uid: "",
        updated_uid: "",
      },
    });

    const countryOptions = ref([]);
    const industryOptions = ref([]);
    const corporationTypeOptions = ref([]);
    const groupOptions = ref([]);

    const channelsMgmtOptions = ref([]);
    const positionInGroupOptions = ref([]);
    const keyParametersTableData = ref([]);
    const keyParametersId = ref(0);

    const parentRelationshipTableData = ref([]);
    const parentRelationshipId = ref(0);

    const relatedRelationshipTableData = ref([]);
    const relatedRelationshipId = ref(0);

    const parentRelationshipItems = ref<Array<TaggingItem>>([]);
    const relatedRelationshipItems = ref<Array<TaggingItem>>([]);

    const salesChannelsTableData = ref([]);

    const channelType = new Map([
      ["pos", "Point of Sale"],
      ["app", "Mobile Applications"],
      ["store", "Online Store"],
    ]);

    const rules = ref({
      name: [
        {
          required: true,
          message: "Company Name is required",
          trigger: "change",
        },
      ],
      // short_key: [
      //   {
      //     required: false,
      //     message: "Company Short-Key is required",
      //     trigger: "change",
      //   },
      // ],
      // last_validation_date: [
      //   {
      //     required: false,
      //     message: "Last Validation Date is required",
      //     trigger: "change",
      //   },
      // ],
      lifecycle_status: [
        {
          required: true,
          message: "Lifecycle Status is required",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: "Email is required",
          trigger: "change",
        },
      ],
      address: [
        {
          required: true,
          message: "Address is required",
          trigger: "change",
        },
      ],
      zip_code: [
        {
          required: true,
          message: "Zip Code is required",
          trigger: "change",
        },
      ],
      city: [
        {
          required: true,
          message: "City is required",
          trigger: "change",
        },
      ],
      country_iso_2: [
        {
          required: true,
          message: "Country is required",
          trigger: "change",
        },
      ],
      // ut_commercial_registry: [
      //   {
      //     required: false,
      //     message: "UT Commercial registry is required",
      //     trigger: "change",
      //   },
      // ],
    });

    const getCompanyData = async () => {
      loading.value = true;
      const { data } = await ApiMerchant.getCompanyInfo({
        id: route.params.id,
      });
      loading.value = false;
      if (data.code == 0) {
        let newObj = {
          year_of_incorporation: String(data.data.year_of_incorporation),
        };
        formData.value = { ...data.data, ...newObj };
        keyParametersTableData.value = data.data.key_parameters;
      }
    };

    const getCompanyChanelData = async () => {
      loading.value = true;
      const { data } = await ApiMerchant.getCompanyChanelList({
        merchant_id: route.params.id,
      });
      loading.value = false;
      if (data.code == 0) {
        salesChannelsTableData.value = data.data.items;
      }
    };

    const getGroupOptions = async () => {
      await ApiMerchant.getGroupData({
        status: 10,
        max_item: "all",
      })
        .then(({ data }) => {
          if (data.code == 0) {
            groupOptions.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getCountryOptions = async () => {
      const { data } = await ApiBase.getCountryData();
      if (data.code == 0) {
        countryOptions.value = data.data;
      }
    };

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "global_industry",
          "global_corporation_type",
          "global_position_in_group",
          "global_channels_mgmt",
          "global_company_parent_relationship",
          "global_company_related_relationship",
        ],
      });
      if (data.code == 0) {
        industryOptions.value = data.data.global_industry.items;
        corporationTypeOptions.value = data.data.global_corporation_type.items;
        positionInGroupOptions.value = data.data.global_position_in_group.items;
        channelsMgmtOptions.value = data.data.global_channels_mgmt.items;
        parentRelationshipItems.value =
          data.data.global_company_parent_relationship.items;
        relatedRelationshipItems.value =
          data.data.global_company_related_relationship.items;
      }
    };

    const deleteCompany = () => {
      Swal.fire({
        text: "Are you sure you would like to deactivate your merchant?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          const { data } = await ApiMerchant.deleteCompany({
            id: route.params.id,
          });

          loading.value = false;
          deleteButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            Swal.fire({
              text: "Your merchant has been deactivated!",
              icon: "success",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              router.push({ name: "merchants" });
            });
          }
        }
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            // submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          const { data } = await ApiMerchant.updateCompany(formData.value);
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg();
          } else {
            showServerErrorMsg(data);
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const backToList = () => {
      if (route.path.indexOf("offline-merchants") != -1) {
        router.push("/merchant-network/offline-merchants");
      } else {
        router.push("/merchants");
      }
    };

    const handleAddKeyParameters = () => {
      keyParametersId.value = 0;
    };

    const handleEditKeyParameters = (index, row) => {
      keyParametersId.value = row.id;
    };

    const updateKeyParametersList = async () => {
      loading.value = true;
      const { data } = await ApiMerchant.getFiscalHistoryList({
        filter_group: [
          {
            field: "merchant_id",
            value: route.params.id,
          },
        ],
      });
      loading.value = false;
      if (data.code == 0) {
        keyParametersTableData.value = data.data.items;
      }
    };

    const handleDeleteKeyParameters = (index, row) => {
      Swal.fire({
        text: "Are you sure you would like to delete it?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          const { data } = await ApiMerchant.deleteFiscalHistory({
            id: row.id,
          });
          loading.value = false;
          if (data.code == 0) {
            Swal.fire({
              text: "Deleted successfully!",
              icon: "success",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              updateKeyParametersList();
            });
          }
        }
      });
    };

    const handleAddParentRelationship = () => {
      parentRelationshipId.value = 0;
    };

    const handleEditParentRelationship = (index, row) => {
      parentRelationshipId.value = row.id;
    };

    const updateParentRelationshipList = async () => {
      loading.value = true;
      const { data } = await ApiMerchant.getMerchantRelationshipList({
        merchant_id: route.params.id,
        tag_short_key: "global_company_parent_relationship",
      });
      loading.value = false;
      if (data.code == 0) {
        parentRelationshipTableData.value = data.data.items;
      }
    };

    const handleDeleteParentRelationship = (index, row) => {
      Swal.fire({
        text: "Are you sure you would like to delete it?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          const { data } = await ApiMerchant.deteleMerchantRelationship({
            id: row.id,
          });
          loading.value = false;
          if (data.code == 0) {
            Swal.fire({
              text: "Deleted successfully!",
              icon: "success",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              updateParentRelationshipList();
            });
          }
        }
      });
    };

    const handleAddRelatedRelationship = () => {
      relatedRelationshipId.value = 0;
    };

    const handleEditRelatedRelationship = (index, row) => {
      relatedRelationshipId.value = row.id;
    };

    const updateRelatedRelationshipList = async () => {
      loading.value = true;
      const { data } = await ApiMerchant.getMerchantRelationshipList({
        merchant_id: route.params.id,
        tag_short_key: "global_company_related_relationship",
      });
      loading.value = false;
      if (data.code == 0) {
        relatedRelationshipTableData.value = data.data.items;
      }
    };

    const handleDeleteRelatedRelationship = (index, row) => {
      Swal.fire({
        text: "Are you sure you would like to delete it?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          const { data } = await ApiMerchant.deteleMerchantRelationship({
            id: row.id,
          });
          loading.value = false;
          if (data.code == 0) {
            Swal.fire({
              text: "Deleted successfully!",
              icon: "success",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              updateRelatedRelationshipList();
            });
          }
        }
      });
    };

    const getRelatedMerchantName = (row) => {
      return row.related_merchant.name;
    };

    const getParentRelationshipLabel = (row, column, cellValue, index) => {
      let obj = parentRelationshipItems.value.find((item: TaggingItem) => {
        return item.value == cellValue;
      });
      return obj?.label;
    };

    const getRelatedRelationshipLabel = (row, column, cellValue, index) => {
      let obj = relatedRelationshipItems.value.find((item: TaggingItem) => {
        return item.value == cellValue;
      });
      return obj?.label;
    };

    const getChannelTypeLabel = (row, column, cellValue, index) => {
      return channelType.get(cellValue);
    };

    const getCountryName = (row, column, cellValue, index) => {
      return row.__show.country_iso_2;
    };

    const viewChanelDetail = (row) => {
      if (row.channel_type == "pos") {
        router.push("/sales-channels/point-of-sale/" + row.id + "/information");
      } else if (row.channel_type == "app") {
        router.push(
          "/sales-channels/mobile-applications/" + row.id + "/information"
        );
      } else if (row.channel_type == "store") {
        router.push("/sales-channels/online-stores/" + row.id + "/Information");
      }
    };

    const customPrefix = shallowRef({
      render() {
        return h(
          "span",
          {
            class: "svg-icon svg-icon-4 me-1",
          },
          [
            h("inline-svg", {
              src: "media/icons/duotune/general/gen014.svg",
            }),
          ]
        );
      },
    });

    onBeforeMount(() => {
      getGroupOptions();
      getCountryOptions();
      getDropdownOptions();
      getCompanyData();
      getCompanyChanelData();
      updateParentRelationshipList();
      updateRelatedRelationshipList();
    });

    onMounted(() => {
      setModuleBCN(t, route, router);
    });

    return {
      commonChangeDefaultDate,
      loading,
      formData,
      rules,
      formRef,
      submitButton,
      deleteButton,
      countryOptions,
      industryOptions,
      corporationTypeOptions,
      channelsMgmtOptions,
      groupOptions,
      positionInGroupOptions,
      keyParametersTableData,
      keyParametersId,
      parentRelationshipTableData,
      parentRelationshipId,
      relatedRelationshipTableData,
      relatedRelationshipId,
      salesChannelsTableData,
      channelType,
      t,
      submit,
      getCompanyData,
      deleteCompany,
      handleAddKeyParameters,
      handleEditKeyParameters,
      handleDeleteKeyParameters,
      updateKeyParametersList,
      handleAddParentRelationship,
      handleEditParentRelationship,
      handleDeleteParentRelationship,
      updateParentRelationshipList,
      handleAddRelatedRelationship,
      handleEditRelatedRelationship,
      handleDeleteRelatedRelationship,
      updateRelatedRelationshipList,
      getRelatedMerchantName,
      getParentRelationshipLabel,
      getRelatedRelationshipLabel,
      getChannelTypeLabel,
      getCountryName,
      backToList,
      viewChanelDetail,
      customPrefix,
    };
  },
});
